const aspectRatio = (url) => {
  if (!url) {
    return {};
  }
  const splittedUrl = url.replace("https://a.storyblok.com/f/", "").split("/");
  const [width, height] = splittedUrl[1].split("x");

  return {
    width,
    height,
  };
};

export const imageData = (imageObject) => ({
  ...imageObject,
  aspectRatio: aspectRatio(imageObject.filename),
  alt: imageObject?.name?.length > 0 ? imageObject.name : imageObject.alt,
  blurDataURL: `${imageObject.filename}/m/100x0/filters:blur(10):quality(1)`
});
