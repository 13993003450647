import { keyframes } from "@theme";

export const lineGrowAndShrink = keyframes({
  "0.0%": {
    transformOrigin: "bottom left",
    transform: "scaleX(0)",
  },
  "16.6%": {
    transformOrigin: "bottom left",
    transform: "scaleX(1)",
  },
  "27.7%": {
    transformOrigin: "bottom right",
    transform: "scaleX(1)",
  },
  "77.7%": {},
  "100%": {
    transformOrigin: "bottom right",
    transform: "scaleX(0)",
  },
});

export const pulse = keyframes({
  "0.0%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0.5,
  },
  "100%": {
    opacity: 1,
  },
});
