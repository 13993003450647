import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// import required modules
import { Mousewheel } from "swiper";

export function VerticalCarousel({ RenderItem, items }) {
  return (
    <Swiper
      direction="vertical"
      mousewheel={true}
      speed={1600}
      modules={[Mousewheel]}
    >
      {items.map((item, position) => (
        <SwiperSlide key={item._uid}>
          <RenderItem item={item} isLastItem={position === items.length - 1} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
