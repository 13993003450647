import Head from "next/head";

import { imageData } from "@utils/imageData";
import constants from "../../../constants";

export function SEO({
  title = "Design for a better world. Tone Studio, Sydney Australia. Expert graphic design in Fundraising, branding, digital and publication design.",
  description = "At Tone Studio, we are a team of like-minded creatives who choose to use our skills for good. Design is a powerful tool that we can use to shine a spotlight on causes, movements and programs that might otherwise go on unnoticed. In this way, we create thought provoking designs to influence and shape our world for the better.",
  author = { name: "Vanessa McCarthy", twitter: "@nativedone" },
  open_graph_image = {
    filename:
      "https://a.storyblok.com/f/126516/1200x628/dc79bfa2ce/open-graph_1200x630_design-for-a-better-world.jpeg",
    alt: "Banner for tonestudio.com.au, featuring our tagline 'Design for a better world'",
  },
  websiteUrl = "https://tonestudio.com.au/",
  slug = undefined,
  meta = [],
}) {
  const image = imageData(open_graph_image);

  const shouldBlockSeo =
    process.env.NEXT_PUBLIC_SITE_URL === constants.urlBlockedForSEO;

  const url = slug ? `${websiteUrl}${slug}` : websiteUrl;

  const metaData = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      property: `og:image`,
      content: image.filename,
    },
    {
      property: `og:image:alt`,
      content: image.alt,
    },
    {
      property: `og:image:width`,
      content: `1200`,
    },
    {
      property: `og:image:height`,
      content: `630`,
    },
    {
      property: `author`,
      content: author.name,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: author.twitter,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:image`,
      content: image.filename,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ].concat(meta);
  return (
    <Head>
      <title>{title}</title>
      {metaData.map((values, i) => (
        <meta key={i} {...values} />
      ))}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <link
        rel="preload"
        href="/fonts/AvenirLTPro/AvenirLTPro45Book.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/AvenirLTPro/AvenirLTPro85Heavy.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/AvenirLTPro/AvenirLTPro35Light.woff2"
        as="font"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/fonts/AvenirLTPro/AvenirLTPro65Medium.woff2"
        as="font"
        crossOrigin=""
      />
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      {shouldBlockSeo && <meta name="robots" content="noindex, nofollow" />}
    </Head>
  );
}
