import { FeaturedPageTitle } from "@components/FeaturedPageTitle";
import { render as richText } from "storyblok-rich-text-react-renderer";
import { Chevron } from "../../components/Chevron";
import { useSwiper } from "swiper/react";

import SbEditable from "storyblok-react";

import { styled } from "@theme";
import { pulse, lineGrowAndShrink } from "@styles/animations";


export function FeaturedSection({ blok, isLastItem }) {
  const swiper = useSwiper();

  return (
    <SbEditable content={blok}>
      <FeaturedSectionContainer>
        <FeaturedPageTitle title={blok.title} />
        <ParagraphList>{richText(blok.rich_text)}</ParagraphList>
        <ChevronController>
          {isLastItem ? null : <Chevron onClick={() => swiper.slideNext()} />}
        </ChevronController>
      </FeaturedSectionContainer>
    </SbEditable>
  );
}

const FeaturedSectionContainer = styled("section", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100%",
  position: "relative",
});

const ParagraphList = styled("div", {
  width: "86ch",
  maxWidth: "85vw",
  textAlign: "center",
  margin: "0 auto",
  p: {
    /* 0.625rem(10px) @ 20rem(320px) increasing to 1.25rem(20px) @ 160rem(2560px) */
    marginBottom: `clamp(0.625rem, calc(0.625rem + ((1vw - 0.2rem) * 0.4464)), 1.25rem)`,

    //fontFamily: "var(--font-family-avenir-lt-pro-35-light)",
    fontStyle: "var(--font-style-normal)",
    fontWeight: "$300",

    letterSpacing: "var(--character-spacing-0)",
    color: "var(--color-000)",

    /* 1rem(16px) @ 20rem(320px) increasing to 3rem(48px) @ 160rem(2560px) */
    fontSize: `clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.8929)), 2.25rem)`,

    /* 1.375rem(22px) @ 20rem(320px) increasing to 3rem(48px) @ 160rem(2560px) */
    lineHeight:
      "clamp(1.375rem, calc(1.375rem + ((1vw - 0.2rem) * 1.1607)), 3rem)",

    /* Safari resize fix */
    minHeight: "0vw",

    "&:last-child": {
      marginBottom: "0px",
    },

    "> a": {
      transition: "all 0.35s linear",
      position: "relative",
      color: "var(--color-000000)",

      "&::before": {
        content: `""`,
        position: "absolute",
        left: "0",
        bottom: "-3px",
        height: "1.5px",
        width: "100%",
        transition: "all 0.35s linear",
        backgroundColor: "black",
        transform: "scaleX(0)",
        transformOrigin: "bottom left",
        animation: `${lineGrowAndShrink} 3s infinite`,
      },
    },

    "@media (hover: hover)": {
      ".chevron-controller:hover": {
        cursor: "pointer",
        transform: "translateY(2px)",
      },
      "> a:hover": {
        fontWeight: "$600",

        "&::before": {
          transformOrigin: "bottom left",
          transform: "scaleX(1)",
          animation: "none",
        },
      },
    },
  },
  "@media (min-width: 1919px)": {
    width: "86ch",
  },
});

const ChevronController = styled("div", {
  zIndex: "10000",
  cursor: "pointer",
  color: "rgba(220, 219, 219, 1)",
  animation: `${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,

  /* 0.640625rem(10.25px) @ 20rem(320px) increasing to 0.861875rem(13.79px) @ 160rem(2560px) */
  fontSize: `clamp(
    0.640625rem,
    calc(0.640625rem + ((1vw - 0.2rem) * 0.158)),
    0.861875rem
  )`,

  /* Safari resize fix */
  minHeight: "0vw",

  position: "relative",
  top: `clamp(
    0.71875rem,
    calc(0.71875rem + ((1vw - 0.2rem) * 1.3616)),
    2.625rem
  )`,
});
