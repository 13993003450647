import {
  resolveStoryBlokRelations,
  useStoryblok,
  getAsyncPageProps,
} from "../lib/storyblok";

import { MainLayout } from "../src/components/layouts/MainLayout";
import { SEO } from "../src/components/SEO";
import { HomePage } from "../src/features/home/views/HomePage";

export default function HomeRoute(props) {
  // use the preview variable to enable the bridge only in preview mode
  const enableBridge = props.preview;
  const story = useStoryblok(props.story, enableBridge);

  return (
    <>
      <MainLayout variant="light" animateMenuOnScroll={false}>
        <HomePage blok={story.content} />
      </MainLayout>
      <SEO {...props.seo} />
    </>
  );
}

export async function getStaticProps({ params, preview = false }) {
  let sbParams = {
    // load only the env version by default (published or draft)
    version: process.env.STORY_BLOK_VERSION,
    resolve_relations: resolveStoryBlokRelations,
  };

  if (preview) {
    // set the version to draft in the preview mode
    sbParams.version = "draft";
    sbParams.cv = Date.now();
  }

  const { story, seo } = await getAsyncPageProps({
    fullSlug: "home",
    sbParams,
  });

  return {
    props: {
      story,
      seo,
      preview,
    },
    revalidate: 3, // revalidate every 3 sec
  };
}
