import { FeaturedSection } from "../../components/FeaturedSection";
import { VerticalCarousel } from "@components/VerticalCarousel";

import { styled } from "@theme";

export function HomePage({ blok }) {
  return (
    <HomePageContainer>
      <VerticalCarousel RenderItem={RenderItem} items={blok.sections} />
    </HomePageContainer>
  );
}

const RenderItem = ({ item, isLastItem }) => {
  return (
    <SliderContainer>
      <FeaturedSection blok={item} isLastItem={isLastItem} />
    </SliderContainer>
  );
};

const HomePageContainer = styled("div", {
  height: `calc(
    100vh - var(--header-height) - var(--secondary-menu-height) -
      env(safe-area-inset-bottom)
  )`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100vw",
  position: "relative",

    /* 2.8125rem(45px) @ 20rem(320px) increasing to 10.21875rem(163.5px) @ 160rem(2560px) */
    paddingTop: `clamp(
      2.8125rem,
      calc(2.8125rem + ((1vw - 0.2rem) * 5.2902)),
      10.21875rem
    )`,

  "@media (min-width: 771px)": {
    height: "calc(100vh - var(--header-height))",
  },
});

const SliderContainer = styled("div", {
  height: "calc(100% - var(--header-height))",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  "@media (min-width: 771px)": {
    height: "100%",
  },
});
