export function Chevron(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.9048028909329828em"
      height="1.0000147831800263em"
      viewBox="0 0 56.568 29.698"
      {...props}
    >
      <g data-name="Group 1504">
        <path
          data-name="Path 2189"
          d="M.707.707l27.577 27.577L55.861.707"
          fill="none"
          stroke="#c8c8c8"
          strokeWidth={2}
        />
      </g>
    </svg>
  );
}
