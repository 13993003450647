import BalanceText from 'react-wrap-balancer'

import { styled } from "@theme";

export function FeaturedPageTitle({ title, variant }) {
  return (
    <FeaturedPageTitleContainer>
      <TitleContainer className="title" variant={variant} >
        <BalanceText>{title}</BalanceText>
        <VerticalSpacingBelowTitle variant={variant} />
      </TitleContainer>

      <Line />
      <VerticalSpacingBelowLine />
    </FeaturedPageTitleContainer>
  );
}

const FeaturedPageTitleContainer = styled("div", {
  width: "100%",
});

const TitleContainer = styled("div", {
  fontWeight: "$300",
  letterSpacing: "var(--character-spacing-0)",
  color: "var(--color-000000)",
  textAlign: "center",

  /* 1.875rem(30px) @ 20rem(320px) increasing to 5rem(80px) @ 160rem(2560px) */
  fontSize: `clamp(
    1.875rem,
    calc(1.875rem + ((1vw - 0.2rem) * 2.2321)),
    5rem
  )`,

  /* 2.25rem(36px) @ 20rem(320px) increasing to 6rem(96px) @ 160rem(2560px) */
  lineHeight: `clamp(
    2.25rem,
    calc(2.25rem + ((1vw - 0.2rem) * 2.6786)),
    6rem
  )`,

  /* Safari resize fix */
  minHeight: "0vw",

  // Center and don't allow touch the borders in very thin devices
  maxWidth: "80vw",
  margin: "0 auto",

  variants: {
    variant: {
      small: {
        fontSize: "clamp(1.25rem, 1.25rem + (1vw - 0.2rem) * 1.6071, 3.5rem)",
      },
    },
  },
});

const VerticalSpacingBelowTitle = styled("div", {
  width: "100%",

  /* 1.6rem(25.6px) @ 20rem(320px) increasing to 4.625rem(74px) @ 160rem(2560px) */
  "--custom-height": `clamp(
    1.6rem,
    calc(1.6rem + ((1vw - 0.2rem) * 2.1607)),
    4.625rem
  )`,
  height: `var(--custom-height)`,

  /* Safari resize fix */
  minHeight: "0vw",


  variants: {
    variant: {
      small: {
        height: `calc(0.5*var(--custom-height))`,
      },
    },
  },
});

const VerticalSpacingBelowLine = styled("div", {
  width: "100%",

  /* 1.6rem(25.6px) @ 20rem(320px) increasing to 4.625rem(74px) @ 160rem(2560px) */
  height: `clamp(
    1.75rem,
    calc(1.75rem + ((1vw - 0.2rem) * 1.875)),
    4.375rem
  )`,

  /* Safari resize fix */
  minHeight: "0vw",
});

const Line = styled("div", {
  margin: "0 auto",

  background:
    "transparent linear-gradient(90deg, #ff3b1e 0%, #000fa0 100%) 0% 0% no-repeat padding-box",

  width: "120px",
  height: "1.6px",
  minHeight: "1.6px",
  "@media (min-width: 321px)": {
    width: "132px",
    height: "1.7px",
    minHeight: "1.7px",
  },
  "@media (min-width: 769px)": {
    width: "210px",
    height: "2px",
    minHeight: "2px",
  },

  "@media (min-width: 1025px)": {
    width: "240px",
    height: "3px",
    minHeight: "3px",
  },
  "@media (min-width: 1281px)": {
    width: "262px",
  },
  "@media (min-width: 1441px)": {
    width: "400px",
    height: " 4px",
    minHeight: "4px",
  },
});
